import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CelbuxService {
  baseUrl = 'https://livelife-admin-httpapi-host.azurewebsites.net/api/app/';
  // baseUrl = 'https://suiderkruis-celbux-api.azurewebsites.net/api/';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  authHeader: HttpHeaders = new HttpHeaders({'Ocp-Apim-Subscription-Key': '7a8f6fda81e548248162dc7904056f09'});

  constructor(
    private http: HttpClient
  ) { }

  registerUser(appUserId) {
    const body = { tenantId: environment.tenantId, appUserId };
    return this.http.post(this.baseUrl + `celbux/customer`, body);
  };

  // gets the coupon list from the middleware api
  getUser(userId) {
    return this.http.get(this.baseUrl + `celbux/user?tenantId=${environment.tenantId}&appUserId=${userId}`);
  };
  // getUserByAppId(appId) {
  //   return this.http.get(this.baseUrl + `register/getuserbyappid/${appId}`, {headers: this.authHeader});
  // };
}
